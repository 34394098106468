.card-container {
  height: 80vh;
}

.icon-container {
  height: 40vh;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container i {
  font-size: 100px;
}

.user-input {
  border: none;
  font-size: 30px;
  text-align: center;
  letter-spacing: 33px;
}

.user-button {
  border-radius: 4px;
  background-color: #151c28;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 10px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 25px;
}

.user-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.user-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.user-button:hover span {
  padding-right: 25px;
}

.user-button:hover span:after {
  opacity: 1;
  right: 0;
}